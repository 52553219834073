import React from 'react'
import Layout from '../components/layout'
import SEO from '../components/seo'

const VelocityBankingExplainedPage: React.FC = () => (
  <Layout>
    <SEO title="Velocity Banking Explained" />
    <h1>Velocity Banking Explained</h1>
  </Layout>
)

export default VelocityBankingExplainedPage
